import React from "react"
import Page from "../components/Page"
import Layout from "../components/Layout"
import { useStoryblok } from "../storyblok"

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblok(pageContext.story)

  return (
    <Layout location={location}>
      <Page story={story} />
    </Layout>
  )
}
